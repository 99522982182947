import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby';
import { linkToId } from '../../assets/js/utils'
import RichText from '../richText'
import ButtonIcon from '../../../static/images/button-icon.svg'
import './cta.scss'

const CallToAction = ( props ) => {

    const data =  props.cta.edges[0].node

    if ( props.type === 'vertical' )
        return (
            <section className='cta cta--vertical'>
                <div className="cta__background">
                    { data.pageImage !== null &&
                        <Img fluid={ data.pageImage.fluid } />
                    }
                </div>
                <div className='cta__container container'>
                    <div className='cta__inner'>
                        <h3 className='cta__title color--kraaijeveld'>{data.pageTitle} <span> {data.pageSubtitle}</span></h3>
                        { data.pageDescription &&
                            <div className="cta__description">
                                <RichText data={data.pageDescription} />
                            </div>
                        }
                        { data.pageButtonLink &&
                            // <Link to={linkToId(data.pageLink.id)} className='button button--primary button--icon'>{data.pageButtonText} <ButtonIcon/></Link>
                            <a href={data.pageButtonLink} className='button button--primary button--icon'>{data.pageButtonText} <ButtonIcon/></a>
                        }
                    </div>
                </div>
            </section>
        )
    else {
      return (
          <section className='cta cta--horizontal'>
              <div className='cta__container container'>
                  <div className='cta__inner'>
                      <div className='cta__content'>
                          <h3 className='cta__title color--kraaijeveld'>{data.pageTitle} <span> {data.pageSubtitle}</span></h3>
                          { data.pageDescription &&
                              <div className="cta__description">
                                <RichText data={data.pageDescription} />
                              </div>
                          }
                          { data.pageButtonLink &&
                          <a href={data.pageButtonLink} className='button button--primary button--icon'>{data.pageButtonText} <ButtonIcon/></a>
                            // <Link to={linkToId(data.pageLink.id)} className='button button--primary button--icon'>{data.pageButtonText} <ButtonIcon/></Link>
                          }
                      </div>
                      { data.pageImage !== undefined &&
                        <img src={ data.pageImage.fixed.src } alt='' className='cta__image'/>
                      }
                  </div>
              </div>
          </section>
      )
    }
}

export default CallToAction
