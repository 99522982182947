import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RichText = ({data}) => {

    console.log(data)

    const Hyperlink = ({ title, slug }) => (
        <a className="t--hyperlink" href={slug} rel="noreferrer help" target="_blank">
            {title}
        </a>
    );

    // Define Rich Text Content
    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className='text'>{children}</p>
    const options = {
        renderText: (text) => text.split("\n").reduce((acc, t, i) => acc.concat([i > 0 && <br />, t]), []),
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.HEADING_1]: (node, children) => <Text>{children}</Text>,
            [INLINES.HYPERLINK]: (node, children) => <Hyperlink title={children} slug={node.data.uri} />
        }
    }

    return (
        documentToReactComponents(data.json, options)
    )
}

export default RichText

